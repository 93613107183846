<template>
  <div class="public" itemscope itemtype="http://schema.org/Organization">
    <public-header />
    <div class="public-main">
      <slot />
    </div>
    <public-footer />
    <client-only>
      <vz-backend-stub-modal />
      <vz-overlay :permanent="!backendAvailable" />
      <vz-feedback-call-modal />
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { schemaData } from '~/common/schema'
import { storeToRefs } from 'pinia';
import { useSeoStore } from '~/stores/seo'
import PublicHeader from '~/components/public/header/public-header.vue'
import PublicFooter from '~/components/public/footer/public-footer.vue'
import { useUserStore } from '~~/stores/personal/user'
import { useBackendStore } from '~/stores/backend'
import { cookieOptions } from '~/common/functions'
import { cookieItems } from '~/composables/useVzFetch'
import {defineAsyncComponent} from 'vue';
import VzFeedbackCallModal from "~/components/modals/vz-feedback-call-modal.vue";

const VzBackendStubModal = defineAsyncComponent(() => import('~/components/modals/vz-backend-stub-modal.vue'))
const VzOverlay = defineAsyncComponent(() => import('~/components/ui-kit/vz-overlay.vue'))

const seoStore = useSeoStore()
const { isAuth } = storeToRefs(useUserStore())
const { backendAvailable } = storeToRefs(useBackendStore())

const { $showDialog, $hideDialog } = useNuxtApp()

const primaryRef = useCookie(cookieItems.primaryRef, { ...cookieOptions(), maxAge: 31536000 })

useHead({
  title: seoStore.seo?.seo?.title || 'Транспортная компания «Возовоз»',
  meta: [
    ...(seoStore.seo?.seo?.meta || {}),
    { property: 'og:title', content: seoStore.seo?.seo?.title || 'Транспортная компания «Возовоз»' },
    { property: 'og:url', content: seoStore.seo?.seo?.canonical },
  ],
  link: seoStore.seo?.seo?.canonical ? [{ rel: 'canonical', href: `${seoStore.seo?.seo?.canonical}` }] : undefined,
  script:[
    {
      type: 'application/ld+json',
      innerHTML: schemaData
    }
  ],
})

onMounted(() => {
  nextTick(() => {
    if (!backendAvailable.value) {
      $showDialog({ name: 'vz-backend-stub-modal' })
    }
  })

  // сохраняем в cookie домен - первоначальный источник перехода на сайт
  if (document?.referrer && !primaryRef.value) {
    const domain = new URL(document.referrer)?.hostname
    if (domain) {
      primaryRef.value = domain.replace('www.', '')
    }
  }
})

watch(() => backendAvailable.value, () => {
  if (backendAvailable.value) {
    $hideDialog({ name: 'vz-backend-stub-modal' })
  } else {
    $showDialog({ name: 'vz-backend-stub-modal' })
  }
})

useRouteCache((helper) => {
  helper.setCacheable().setMaxAge(3600)
})
</script>

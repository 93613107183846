export const schemaData = {
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  "@id": "Грузоперевозки по России",
  "name": "Транспортная компания Возовоз",
  "logo": "https://vozovoz.ru/svg/logo.svg",
  "map": "https://vozovoz.ru/directions/",
  "paymentAccepted": "Наличными, безналичная оплата, онлайн, по карте в мобильном приложении Возовоз",
  "address": [
    {
      "streetAddress": "Москва, Дмитровское шоссе, 31 А, строение 1",
      "addressCountry": "Россия",
      "postalCode": "127550"
    }
  ],
  "telephone": "+7(499)705-97-79",
  "alternateName": "Vozovoz",
  "description": "Автомобильные грузоперевозки с компанией Возовоз - одной из ведущих транспортных компаний в России.",
  "hasOfferCatalog": {
    "@context": "https://schema.org",
    "@type": "OfferCatalog",
    "@id": "Список услуг",
    "url": "https://vozovoz.ru/cargo/",
    "itemListElement": [  
      {
        "@type": "Offer",
        "areaServed": "Россия",
        "name": "Сборные перевозки",
        "description": "Междугородные перевозки сборных грузов в РФ, Беларуси, Казахстану",
        "priceCurrency": "RUB",
        "identifier": "https://vozovoz.ru/delivery-russia/"
      },
      {
        "@type": "Offer",    
        "areaServed": "Россия",
        "name": "Доставка на маркетплейсы",
        "description": "Доставка в торговые сети и маркетплейсы Ozon, Wildberries, Яндекс Маркет",
        "priceCurrency": "RUB",
        "identifier": "https://vozovoz.ru/networkshops/"
      },
      {
        "@type": "Offer",
        "areaServed": "Россия",
        "name": "Доставка крупногабаритных грузов",
        "description": "Перевозка грузов, превышающих стандартные размеры 1,2*0,8*1,75м",
        "priceCurrency": "RUB",
        "identifier": "https://vozovoz.ru/shippingtypes/oversized/"
      },
      {
        "@type": "Offer",
        "areaServed": "Россия",
        "name": "Доставка от двери до двери",
        "description": "Доставляем груз от адреса отправления до адреса получения",
        "priceCurrency": "RUB",
        "identifier": "https://vozovoz.ru/address-delivery/"
      },
      {
        "@type": "Offer",
        "areaServed": "Россия",
        "name": "Перевозка вещей по межгороду",
        "description": "Междугородная перевозка вещей и багажа по РФ",
        "priceCurrency": "RUB",
        "identifier": "https://vozovoz.ru/shippingtypes/personalitemstransportation/"
      }
    ]
  }
}